<template>
  <div
    class="z-90 fixed inset-0 overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-75 p-4 lg:p-8"
    :class="{ hidden: isHidden }"
    tabindex="-1"
    role="dialog"
    aria-labelledby="tk-modal-simple"
    aria-hidden="false"
  >
    <div
      :class="large ? 'max-w-2xl' : 'max-w-md'"
      class="flex flex-col rounded shadow-sm bg-white overflow-hidden w-full mx-auto"
      role="document"
    >
      <div class="p-5 lg:p-6 grow w-full flex space-x-5">
        <div
          :class="displayAlertComponents"
          class="w-16 h-16 flex-none flex items-center justify-center rounded-full bg-red-100"
        >
          <svg
            class="hi-solid hi-shield-exclamation inline-block w-8 h-8 text-red-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div>
          <h4 class="text-xl font-semibold mb-1">{{ titleInfoMessage }}</h4>
          <p class="text-gray-600">
            {{ bodyInfoMessage }}
          </p>
          <slot></slot>
        </div>
      </div>
      <div class="py-4 px-5 lg:px-6 w-full bg-gray-50 text-right space-x-1">
        <button
          type="button"
          :class="[{ 'display-none': !showCancelButton }, alertText]"
          @click.stop="$emit('answerUser', false)"
          class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-5 text-sm rounded border-transparent focus:ring focus:ring-opacity-50"
        >
          {{ buttonCancelMessage }}
        </button>
        <button
          type="button"
          :disabled="!okButtonEnabled"
          :class="okButtonEnabled ? variantAcceptButton : 'button-disabled'"
          @click.stop="$emit('answerUser', true)"
          class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-5 text-sm rounded border-red-700 bg-red-700 text-white hover:text-white hover:bg-red-800 hover:border-red-800 focus:ring focus:ring-red-500 focus:ring-opacity-50 active:bg-red-700"
        >
          {{ buttonConfirmMessage }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MessageConfirmation',
  data() {
    return {};
  },
  emits: ['answerUser'],
  props: {
    titleInfoMessage: {
      type: String,
      required: false,
      default: 'This is an info alert',
    },
    bodyInfoMessage: {
      type: String,
      required: false,
      default: 'Are you sure you want to perform this action?',
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonConfirmMessage: {
      type: String,
      required: false,
      default: 'Yes, perform',
    },
    buttonCancelMessage: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    showCancelButton: {
      tyep: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'info',
    },
    isHidden: {
      type: Boolean,
      required: false,
      default: true,
    },
    okButtonEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const displayAlertComponents = computed(() => {
      if (props.variant == 'alert') {
        return '';
      }
      return 'display-none';
    });
    const alertText = computed(() => {
      if (props.variant == 'alert') {
        return 'focus:ring-red-500 text-red-600 hover:text-red-400 active:text-red-600';
      }
      return '';
    });
    const variantAcceptButton = computed(() => {
      if (props.variant == 'alert') {
        return 'alert-accept-button';
      }
      return 'info-accept-button';
    });
    return { displayAlertComponents, variantAcceptButton, alertText };
  },
});
</script>
<style scoped>
.alert-accept-button {
  @apply border-red-700;
  @apply bg-red-700;
  @apply text-white;
  @apply hover:text-white;
  @apply hover:bg-red-800;
  @apply hover:border-red-800;
  @apply focus:ring focus:ring-red-500 focus:ring-opacity-50;
  @apply active:bg-red-700;
}
.alert-dismiss-button {
  @apply text-red-600;
  @apply hover:text-red-400;
  @apply focus:ring focus:ring-red-500 focus:ring-opacity-50;
  @apply active:text-red-600;
}
.info-accept-button {
  @apply border-blue-700;
  @apply bg-blue-700;
  @apply text-white;
  @apply hover:text-white;
  @apply hover:bg-blue-800;
  @apply hover:border-blue-800;
  @apply focus:ring focus:ring-blue-500 focus:ring-opacity-50;
  @apply active:bg-blue-700;
}

.button-disabled {
  @apply border-gray-300;
  @apply bg-gray-300;
  @apply text-gray-700;
  @apply hover:border-gray-300;
  @apply hover:bg-gray-300;
  @apply hover:text-gray-700;
}

.display-none {
  @apply hidden;
}
</style>

<template>
  <div v-if="result?.user">
    <h2 class="page-title">{{ result.user.name }}</h2>
    <div class="relative">
      <div
        class="flex flex-col rounded shadow-sm bg-white overflow-hidden relative"
      >
        <div class="p-5 lg:p-6 flex-grow w-full">
          <ul
            class="border border-gray-200 rounded bg-white divide-y divide-gray-200"
          >
            <li class="p-4 flex justify-between items-center">
              <span class="font-semibold text-sm mr-1">Email</span>
              <span class="text-sm hidden lg:inline-block">{{
                result.user.email
              }}</span>
            </li>
            <li class="p-4 flex justify-between items-center">
              <span class="font-semibold text-sm mr-1">Auth0 ID</span>
              <span class="text-sm hidden lg:inline-block">{{
                result.user.auth0_identifier
              }}</span>
            </li>
          </ul>
        </div>
        <!-- roles section -->
        <form @submit.prevent="saveRoles" class="mb-4">
          <div class="p-5 lg:p-6">
            <div class="space-y-2">
              <h3 class="flex items-center my-8">
                <span
                  aria-hidden="true"
                  class="flex-grow bg-gray-200 rounded h-0.5"
                ></span>
                <span class="text-lg font-medium mx-3">Roles</span>
                <span
                  aria-hidden="true"
                  class="flex-grow bg-gray-200 rounded h-0.5"
                ></span>
              </h3>
              <!-- Tabs-->
              <div
                class="flex flex-col rounded-lg bg-white shadow dark:bg-gray-800 dark:text-gray-100"
              >
                <TabGroup>
                  <!-- Nav Tabs -->
                  <TabList
                    class="flex items-center border-b border-gray-200/75 text-sm dark:border-gray-700"
                  >
                    <Tab class="flex-grow" as="template" v-slot="{ selected }">
                      <button
                        class="-mb-px flex items-center space-x-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-indigo-500 focus:ring-opacity-25 md:px-5"
                        :class="{
                          'border-indigo-500 text-indigo-600 dark:border-indigo-500 dark:text-indigo-400':
                            selected,
                          'border-transparent text-gray-600 hover:border-gray-200 hover:text-indigo-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-indigo-400':
                            !selected,
                        }"
                      >
                        <span class="w-full">
                          Roles on the user's namespaces
                        </span>
                      </button>
                    </Tab>
                    <Tab class="flex-grow" as="template" v-slot="{ selected }">
                      <button
                        class="-mb-px flex items-center flex-grow space-x-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-indigo-500 focus:ring-opacity-25 md:px-5"
                        :class="{
                          'border-indigo-500 text-indigo-600 dark:border-indigo-500 dark:text-indigo-400':
                            selected,
                          'border-transparent text-gray-600 hover:border-gray-200 hover:text-indigo-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-indigo-400':
                            !selected,
                        }"
                      >
                        <span class="w-full">
                          Roles on all Warehouse namespaces
                        </span>
                      </button>
                    </Tab>
                  </TabList>
                  <!-- END Nav Tabs -->

                  <!-- Tab Content -->
                  <TabPanels class="grow">
                    <TabPanel class="divide-y divide-gray-200">
                      <div
                        v-for="role in rolesBySection.namespaceRoles"
                        key="role.id"
                        class="space-x-2 p-2"
                      >
                        <label
                          class="font-medium leading-relaxed flex flex-row justify-between p-1"
                        >
                          <div>
                            {{ role.name }}
                            <span
                              class="block text-sm text-gray-500 dark:text-gray-400"
                            >
                              {{
                                role.description ??
                                'No description provided, please contact support'
                              }}
                            </span>
                          </div>
                          <input
                            class="h-6 w-10 self-center rounded-full text-indigo-500 transition-all duration-150 ease-out form-switch focus:ring focus:ring-indigo-500 focus:ring-opacity-50 dark:bg-gray-700 dark:ring-offset-gray-900 dark:checked:bg-current"
                            type="checkbox"
                            v-model="checkedRoleIds"
                            :value="role.id"
                          />
                        </label>
                      </div>
                    </TabPanel>
                    <TabPanel class="divide-y divide-gray-200">
                      <div
                        v-for="role in rolesBySection.worldRoles"
                        key="role.id"
                        class="flex flex-row justify-between space-x-2 p-2"
                      >
                        <label
                          :for="role.id"
                          class="font-medium leading-relaxed"
                        >
                          {{ role.name }}
                          <span
                            class="block text-sm text-gray-500 dark:text-gray-400"
                          >
                            {{
                              role.description ??
                              'No description provided, please contact support'
                            }}
                          </span>
                        </label>
                        <input
                          class="h-6 w-10 self-center rounded-full text-indigo-500 transition-all duration-150 ease-out form-switch focus:ring focus:ring-indigo-500 focus:ring-opacity-50 dark:bg-gray-700 dark:ring-offset-gray-900 dark:checked:bg-current"
                          type="checkbox"
                          v-model="checkedRoleIds"
                          :id="role.id"
                          :name="role.id"
                          :value="role.id"
                        />
                      </div>
                    </TabPanel>
                  </TabPanels>
                  <!-- END Tab Content -->
                </TabGroup>
              </div>
            </div>
          </div>

          <div class="px-5 lg:px-6">
            <dm-button
              type="submit"
              :loading="changeRolesLoading"
              :enabled="needsSaveRoles"
              >Save Roles</dm-button
            >
          </div>
        </form>
        <!-- end roles section -->

        <!-- namespaces section -->
        <div class="p-5 lg:p-6">
          <div>
            <h3 class="flex items-center my-4">
              <span
                aria-hidden="true"
                class="flex-grow bg-gray-200 rounded h-0.5"
              ></span>
              <span class="text-lg font-medium mx-3">Namespaces</span>
              <span
                aria-hidden="true"
                class="flex-grow bg-gray-200 rounded h-0.5"
              ></span>
            </h3>
            <div>
              <div
                v-for="namespace in result.user.namespaces"
                :key="namespace.id"
                class="font-semibold inline-flex px-2 py-1 leading-4 items-center space-x-1 text-xs mr-2 rounded text-indigo-700 bg-indigo-200"
              >
                <span>{{ namespace.name }}</span>
                <button
                  @click="removeNamespaceFromUserHandler(namespace.id)"
                  type="button"
                  class="focus:outline-none text-indigo-600 hover:text-indigo-400 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 active:text-indigo-600"
                >
                  <svg
                    class="hi-solid hi-x inline-block w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="px-5 pb-5 lg:px-6 lg:pb-6">
          <namespace-search
            @selected="addNamespace"
            :buttonLoading="
              addNamespaceToUserLoading || removeNamespaceFromUserLoading
            "
          />
        </div>

        <error-display
          :error="addNamespaceToUserError"
          headerMessage="Failed to add namespace to user."
        />
        <error-display
          :error="removeNamespaceFromUserError"
          headerMessage="Failed to remove namespace from user."
        />
      </div>
      <!-- end namespaces section-->
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { isEqual, difference } from 'lodash';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';

import { graphql } from '../../__generated__/gql';
import { Namespace } from '../../__generated__/graphql';

import NamespaceSearch from '../NamespaceSearch.vue';
import ErrorDisplay from '../ErrorDisplay.vue';

const PAGE_QUERY = graphql(`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      auth0_identifier
      name
      email
      roles {
        id
        name
      }
      namespaces {
        id
        name
      }
    }
    roles {
      id
      name
      description
    }
  }
`);

const SAVE_ROLES_MUTATION = graphql(`
  mutation changeRoles($userId: ID!, $add: [ID!]!, $remove: [ID!]!) {
    changeUserRoles(input: { userId: $userId, add: $add, remove: $remove }) {
      user {
        id
        auth0_identifier
        name
        email
        roles {
          id
          name
        }
      }
    }
  }
`);

const ADD_NAMESPACE_MUTATION = graphql(`
  mutation addNamespaceToUser($userId: ID!, $namespaceId: ID!) {
    addNamespaceToUser(userId: $userId, namespaceId: $namespaceId) {
      user {
        id
        auth0_identifier
        name
        email
        namespaces {
          id
          name
        }
      }
    }
  }
`);

const REMOVE_NAMESPACE_MUTATION = graphql(`
  mutation removeNamespaceFromUser($userId: ID!, $namespaceId: ID!) {
    removeNamespaceFromUser(userId: $userId, namespaceId: $namespaceId) {
      user {
        id
        auth0_identifier
        name
        email
        namespaces {
          id
          name
        }
      }
    }
  }
`);

const route = useRoute();
const id = Array.isArray(route.params.id)
  ? route.params.id[0]
  : route.params.id;
const userId = ref(id);
const checkedRoleIds: Ref<string[]> = ref([]);

watch(
  () => route.params.id,
  async (newId) => {
    const newValue = Array.isArray(newId) ? newId[0] : newId;
    userId.value = newValue;
  }
);

const { result } = useQuery(PAGE_QUERY, () => ({ id: userId.value }), {
  fetchPolicy: 'cache-and-network',
});

const {
  mutate: changeRoles,
  loading: changeRolesLoading,
  error: changeRolesError,
} = useMutation(SAVE_ROLES_MUTATION);

const {
  mutate: addNamespaceToUser,
  loading: addNamespaceToUserLoading,
  error: addNamespaceToUserError,
} = useMutation(ADD_NAMESPACE_MUTATION);

const {
  mutate: removeNamespaceFromUser,
  loading: removeNamespaceFromUserLoading,
  error: removeNamespaceFromUserError,
} = useMutation(REMOVE_NAMESPACE_MUTATION);

const userRoleIds = computed(() =>
  result.value?.user?.roles.map((role) => role.id)
);

watch(result, (value) => {
  if (value?.user?.roles) {
    checkedRoleIds.value = [];
    for (const role of value.user.roles) {
      checkedRoleIds.value.push(role.id);
    }
  }
});

const needsSaveRoles = computed(
  () => !isEqual(checkedRoleIds.value, userRoleIds.value)
);

const saveRoles = function () {
  if (result.value && result.value.user?.id && userRoleIds.value) {
    const roleIdsToAdd = difference(checkedRoleIds.value, userRoleIds.value);
    const roleIdsToRemove = difference(userRoleIds.value, checkedRoleIds.value);
    if (roleIdsToAdd.length > 0 || roleIdsToRemove.length > 0) {
      changeRoles({
        userId: result.value?.user?.id,
        add: roleIdsToAdd,
        remove: roleIdsToRemove,
      });
    }
  }
};

const removeNamespaceFromUserHandler = function (namespaceId: string) {
  if (result.value?.user?.id) {
    removeNamespaceFromUser({
      userId: result.value.user.id,
      namespaceId: namespaceId,
    });
  }
};

const addNamespace = function (event: Namespace) {
  if (result.value?.user?.id) {
    addNamespaceToUser({
      userId: result.value.user.id,
      namespaceId: event.id,
    });
  }
};

const rolesBySection = computed(() => ({
  namespaceRoles:
    result.value?.roles.filter((role) => !role.name.match(/[Ww]orld/)) ?? [],
  worldRoles:
    result.value?.roles.filter((role) => !!role.name.match(/[Ww]orld/)) ?? [],
}));
</script>

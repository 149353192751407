<template>
  <!-- Token Form Dialog -->
  <create-token-confirmation
    :isHidden="createTokenDialogHidden"
    bodyInfoMessage=""
    titleInfoMessage="Create Token"
    buttonConfirmMessage="Create"
    variant="info"
    :okButtonEnabled="formEnabled"
    :large="true"
    @answerUser="createTokenDialogClosed"
  >
    <div class="p-5 lg:p-6">
      <div class="space-y-2">
        <label class="font-medium" for="form-elements-description"
          >Description</label
        >
        <input
          v-model="description"
          type="text"
          class="form-input"
          id="form-elements-description"
        />
      </div>
    </div>

    <div class="p-5 lg:p-6">
      <div class="space-y-1">
        <label class="font-medium" for="form-elements-expiration"
          >Expiration</label
        >
        <select
          v-model="expiration"
          class="form-input"
          id="form-elements-description"
        >
          <option :value="{ value: 1, unit: 'w' }" key="1week" selected>
            1 week
          </option>
          <option :value="{ value: 1, unit: 'M' }" key="1months" selected>
            1 month
          </option>
          <option :value="{ value: 2, unit: 'M' }" key="2months" selected>
            2 months
          </option>
          <option :value="{ value: 3, unit: 'M' }" key="3months" selected>
            3 months
          </option>
          <option :value="{ value: 6, unit: 'M' }" key="6months" selected>
            6 months
          </option>
        </select>
      </div>
    </div>

    <div class="p-5 lg:p-6">
      <div class="space-y-1">
        <h3 class="flex items-center my-8">
          <span
            aria-hidden="true"
            class="flex-grow bg-gray-200 rounded h-0.5"
          ></span>
          <span class="text-lg font-medium mx-3">Permissions</span>
          <span
            aria-hidden="true"
            class="flex-grow bg-gray-200 rounded h-0.5"
          ></span>
        </h3>
        <div class="mt-2 grid grid-cols-3">
          <label
            v-for="permission of userPermissions"
            class="inline-flex items-center mr-6 text-sm"
          >
            <input
              type="checkbox"
              :id="permission"
              :value="permission"
              v-model="selectedPermissions"
              class="border border-gray-200 rounded h-4 w-4 text-indigo-500 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            />
            <span class="ml-2">{{ permission }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="p-5 lg:p-6">
      <div class="space-y-2">
        <h3 class="flex items-center my-8">
          <span
            aria-hidden="true"
            class="flex-grow bg-gray-200 rounded h-0.5"
          ></span>
          <span class="text-lg font-medium mx-3">Namespaces</span>
          <span
            aria-hidden="true"
            class="flex-grow bg-gray-200 rounded h-0.5"
          ></span>
        </h3>
        <div class="mt-2">
          <div class="mt-2 grid grid-cols-3">
            <label
              v-for="namespace of userNamespaces"
              class="inline-flex items-center mr-6 text-sm"
            >
              <input
                type="checkbox"
                :id="namespace.id"
                :value="namespace.id"
                v-model="selectedNamespaces"
                class="border border-gray-200 rounded h-4 w-4 text-indigo-500 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              />
              <span class="ml-2">{{ namespace.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </create-token-confirmation>
  <!-- End of Token Form Dialog -->

  <!-- Created Token Information-->
  <div
    v-if="newToken"
    class="p-4 md:p-5 rounded text-emerald-700 bg-emerald-100 dark:text-emerald-100 dark:bg-emerald-900 dark:bg-opacity-75"
  >
    <div class="flex items-center mb-2">
      <svg
        class="hi-solid hi-check-circle inline-block w-5 h-5 mr-3 flex-none text-emerald-500 dark:text-emerald-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd"
        />
      </svg>
      <h3 class="font-semibold">Token Created</h3>
    </div>
    <p class="ml-8">
      Make sure to copy your personal access token now. You won’t be able to see
      it again!
    </p>
    <p class="ml-10 mt-2 bg-gray-100 rounded-md p-3">
      <em>{{ newToken }}</em> <button-copy-clipboard :dataToCopy="newToken" />
    </p>
  </div>
  <!-- End of Created Token Information-->

  <h2 class="page-title">Tokens</h2>
  <div class="flex flex-row justify-end mb-4">
    <DmButton @click.prevent="createNewTokenClicked">Create New Token</DmButton>
  </div>

  <Table
    v-if="result?.viewer"
    center-header
    :headers="['Description', 'Expires At', 'Actions']"
    :hasNextPage="!!result?.viewer.tokens.pageInfo.hasNextPage"
    :hasPreviousPage="!!result?.viewer.tokens.pageInfo.hasPreviousPage"
    @changePage="(e) => (e === 'next' ? loadNext() : loadPrevious())"
  >
    <tr
      v-for="tokenEdge of result.viewer.tokens.edges"
      :key="tokenEdge.node.id"
      class="border-b border-gray-200 text-center even:bg-gray-50"
    >
      <td class="p-3 text-center">
        {{ tokenEdge.node.description }}
      </td>
      <td>
        {{ tokenEdge.node.expiresAt }}
      </td>
      <td>
        <DmButton
          variant="danger"
          size="xs"
          @click="deleteTokenClicked(tokenEdge.node.id)"
          >Delete</DmButton
        >
      </td>
    </tr>
  </Table>
</template>

<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable';
import dayjs, { ManipulateType, OpUnitType } from 'dayjs';
import { Ref, computed, ref } from 'vue';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/outline';

import { graphql } from '../../__generated__/gql';

import CreateTokenConfirmation from '../base/DialogMessage.vue';
import DmButton from '@/components/base/Button.vue';
import ButtonCopyClipboard from '@/components/base/ButtonCopyClipboard.vue';
import { getUserPermissions, getUserNamespaces } from '../../auth';
import Table from '../base/Table.vue';

interface ViewerQueryVariables {
  first?: number;
  after?: string;
  last?: number;
  before?: string;
}

const numberOfEntriesToDisplay = 10;

const viewerQuery = graphql(`
  query Viewer($first: Int, $after: String, $last: Int, $before: String) {
    viewer {
      tokens(first: $first, after: $after, last: $last, before: $before) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
          startCursor
        }
        edges {
          node {
            id
            description
            expiresAt
          }
        }
      }
    }
  }
`);

const deleteTokenMutation = graphql(`
  mutation DeleteUserToken($id: ID!) {
    deleteUserToken(id: $id) {
      id
      success
    }
  }
`);

const createTokenMutation = graphql(`
  mutation CreateUserToken(
    $description: String!
    $expiresAt: DateTime
    $permissions: [Permission!]!
    $namespaceIds: [ID!]!
  ) {
    createUserToken(
      input: {
        description: $description
        expiresAt: $expiresAt
        permissions: $permissions
        namespaceIds: $namespaceIds
      }
    ) {
      token
    }
  }
`);

// A flag to indicate if the 'create token' should be displayed.
const createTokenDialogHidden = ref(true);

// Getting the user permissions and namespaces from the identity token, to populate the form.
const userPermissions = getUserPermissions();
const userNamespaces = getUserNamespaces();

// Form content.
const selectedPermissions: Ref<string[]> = ref([]);
const selectedNamespaces: Ref<string[]> = ref([]);
const description = ref('');

// The lifetime of the token, as a value/unit pair, where 'unit'
// is one of those defined by dayjs
// https://day.js.org/docs/en/display/difference#list-of-all-available-units.
const expiration = ref({ value: 1, unit: 'w' });

// The newly created token.
const newToken: Ref<string | undefined> = ref();

// Whether the form contains the minimum information to create a token.
const formEnabled = computed(() => {
  return (
    description.value.trim() != '' &&
    selectedPermissions.value.length > 0 &&
    selectedNamespaces.value.length > 0
  );
});

// Tokens pagination variables.
const tokensVariables: Ref<ViewerQueryVariables> = ref({
  first: numberOfEntriesToDisplay,
  last: undefined,
  before: undefined,
  after: undefined,
});

// Tokens list query.
const { result, fetchMore } = useQuery(viewerQuery, tokensVariables);

// Resets all the field in the form, this must be called whenever the form is to be displayed
// for a new token creation.
function resetForm() {
  selectedPermissions.value = [];
  selectedNamespaces.value = [];
  description.value = '';
  expiration.value = { value: 1, unit: 'w' };
}

// The mutation that creates a new token.
const { mutate: createUserToken, onDone: createUserTokenDone } = useMutation(
  createTokenMutation,
  () => ({
    refetchQueries: [{ query: viewerQuery }],
    variables: {
      description: description.value,
      expiresAt: dayjs()
        .add(expiration.value.value, expiration.value.unit as ManipulateType)
        .toISOString(),
      permissions: selectedPermissions.value,
      namespaceIds: selectedNamespaces.value,
    },
  })
);

// The mutation that deletes a token.
const { mutate: deleteUserToken } = useMutation(deleteTokenMutation, {
  refetchQueries: [{ query: viewerQuery }],
});

createUserTokenDone((result) => {
  newToken.value = result?.data?.createUserToken.token ?? undefined;
});

const createTokenDialogClosed = async (answer: boolean) => {
  createTokenDialogHidden.value = true;
  if (answer) {
    createUserToken();
  }
};

// Move pagination forward.
function loadNext() {
  fetchMore({
    variables: {
      first: numberOfEntriesToDisplay,
      last: undefined,
      after: result?.value?.viewer.tokens.pageInfo.endCursor ?? undefined,
    },
    updateQuery: (nextResult, { fetchMoreResult }) =>
      fetchMoreResult || nextResult,
  });
}

// Move pagination backward.
function loadPrevious() {
  fetchMore({
    variables: {
      last: numberOfEntriesToDisplay,
      first: undefined,
      before: result?.value?.viewer.tokens.pageInfo.startCursor ?? undefined,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function deleteTokenClicked(tokenId: string) {
  deleteUserToken({ id: tokenId });
}

function createNewTokenClicked() {
  resetForm();
  createTokenDialogHidden.value = false;
}
</script>

<style>
.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}
</style>

import { graphql } from '../../__generated__/gql';

// An item to display all namespaces
export const SELECT_ALL_NAMESPACES: { id: string; name: string } = {
  id: '[ALL]',
  name: 'All namespaces',
};

// Query to list all namespaces
export const QUERY_ALL_NAMESPACES = graphql(`
  query NamespaceListing {
    namespaces {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`);

import { InjectionKey, Ref, createApp, h, inject, provide, ref } from 'vue';
import App from './App.vue';
import './index.css';
import {
  ApolloClient,
  createHttpLink,
  FieldPolicy,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { Auth0 } from './auth';
import { router } from './routes';
import * as Sentry from '@sentry/vue';

// Base components
import Button from './components/base/Button.vue';
import DateComponent from './components/base/Date.vue';
import { Action } from './components/base/CommandPalette.vue';
import { getActions } from './resourceActions';

//Toast plugin
import Toast from 'vue-toastification';
import {
  PluginOptions,
  ToastOptionsAndRequiredContent,
} from 'vue-toastification/dist/types/types';
import 'vue-toastification/dist/index.css';
import { uuid4 } from '@sentry/utils';

interface Data {
  items: any[];
}

export const GLOBAL_LOADING_REF: InjectionKey<Ref<boolean>> =
  Symbol('global.loading.ref');
export const GLOBAL_FETCH: InjectionKey<typeof fetch> = Symbol('global.fetch');
export const GLOBAL_PAGE_ACTIONS: InjectionKey<() => Action[]> = Symbol(
  'global.page-actions'
);
export const GLOBAL_ADD_PAGE_ACTION: InjectionKey<(action: Action) => void> =
  Symbol('global.add-page-action');

async function init() {
  const app = createApp({
    setup() {
      const auth: any = inject('Auth');
      const httpLink = createHttpLink({
        uri: import.meta.env.VITE_API_ENDPOINT,
      });

      const authLink = setContext(async (_, { headers }) => {
        let accessToken: any;
        try {
          if (import.meta.env.VITE_API_TOKEN) {
            accessToken = import.meta.env.VITE_API_TOKEN;
          } else {
            accessToken = await auth.getTokenSilently();
          }
          return {
            headers: {
              ...headers,
              authorization: accessToken ? `Bearer ${accessToken}` : '',
              'x-request-id':
                Sentry.getCurrentScope().getPropagationContext().traceId,
            },
          };
        } catch (e: any) {
          if (e?.error === 'login_required') {
            accessToken = await auth.getTokenWithPopup();
            return {
              headers: {
                ...headers,
                authorization: accessToken ? `Bearer ${accessToken}` : '',
                'x-request-id':
                  Sentry.getCurrentScope().getPropagationContext().traceId,
              },
            };
          }
          throw new Error(e);
        }
      });

      // Cache implementation
      const cache = new InMemoryCache({
        typePolicies: {
          User: {
            fields: {
              roles: {
                // Replace all roles instead of trying to merge.
                // This is necessary because the client does not know how to
                // merge the nested "roles" field in User, and issues a warning that
                // data might be loss.
                merge(existing, incoming) {
                  return incoming;
                },
              },
              namespaces: {
                // Idem for namespaces.
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
          Publication: {
            fields: {
              uploadUrl: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
          Query: {
            fields: {
              publications: cursorPaginatedField(),
              files: cursorPaginatedField(),
            },
          },
        },
      });

      function cursorPaginatedField(): FieldPolicy {
        return {
          /*The cache stores a separate value for each combination 
          of argument values provided when querying the field*/
          keyArgs: ['filterBy'],
          merge(existing: Data, incoming: Data) {
            return {
              ...incoming,
              items: [...(existing?.items || []), ...(incoming?.items || [])],
            };
          },
        };
      }
      // Create the apollo client
      const apolloClient = new ApolloClient({
        link: authLink.concat(httpLink),
        cache,
      });

      provide(DefaultApolloClient, apolloClient);

      const globalLoading = ref(false);
      provide(GLOBAL_LOADING_REF, globalLoading);
      provide(GLOBAL_FETCH, (...args) => {
        globalLoading.value = true;
        const response = fetch(...args);
        globalLoading.value = false;
        return response;
      });

      const pageActions = ref<Action[]>([]);
      const addPageAction = (action: Action) => {
        pageActions.value = [...pageActions.value, action];
      };
      provide(GLOBAL_PAGE_ACTIONS, () => pageActions.value);
      provide(GLOBAL_ADD_PAGE_ACTION, addPageAction);
      router.afterEach((to, _from, _fail) => {
        pageActions.value = getActions(
          `${to.name?.toString()}s`,
          to.params?.id as string
        );
      });
    },
    render: () => h(App),
  });

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    trackComponents: true,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_API_ENDPOINT.includes('localhost')
      ? 'development'
      : 'production',
    // Performance Monitoring
    tracesSampleRate: 0.1, // This sets the sample rate at 10%.
    tracePropagationTargets: ['https://api.demarque.dev/graphql'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  app.use(router);

  const auth0 = await Auth0.init({
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    },
    domain: 'demarque.auth0.com',
    clientId: 'eBfORU8GmdlRDus2t6iSStx5I4c4CWnA',
    //clientId: process.env.VUE_APP_AUTH0_CLIENT_KEY,
    //domain: "process.env.VUE_APP_AUTH0_DOMAIN,
    audience: 'https://warehouse.demarque.dev', //process.env.VUE_APP_AUTH0_AUDIENCE,
    redirectUri: window.location.origin,
  });

  app
    .use(auth0)
    .component('dm-button', Button)
    .component('dm-date', DateComponent)
    .mount('#app');

  const toastOptions: PluginOptions = {
    maxToasts: 10,
    // Discards duplicated toasts.
    filterBeforeCreate: (
      toast: ToastOptionsAndRequiredContent,
      toasts: ToastOptionsAndRequiredContent[]
    ) => {
      if (toasts.find((t) => t.content === toast.content)) {
        return false;
      }
      return toast;
    },
  };
  app.use(Toast, toastOptions);
}

init();

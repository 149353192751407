<template>
  <nav
    @click="emits('mobileSidebarClosed')"
    id="page-sidebar"
    class="flex flex-col z-100 lg:z-0 fixed top-0 left-0 bottom-0 w-full lg:w-64 h-full bg-[#313947] text-gray-200 transform transition-transform duration-500 ease-out -translate-x-full lg:translate-x-0"
    aria-label="Main Sidebar Navigation"
    :class="{
      '-translate-x-full': !$props.mobileSidebarOpen,
      'translate-x-0': $props.mobileSidebarOpen,
      'lg:-translate-x-full': !$props.desktopSidebarOpen,
      'lg:translate-x-0': $props.desktopSidebarOpen,
    }"
  >
    <!-- Sidebar Header -->
    <div
      class="relative h-16 bg-gray-600 bg-opacity-25 flex-none flex items-center justify-between lg:justify-center px-4 w-full"
    >
      <!-- Brand -->
      <router-link
        id="logo"
        to="/"
        class="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-white-600 hover:text-white-400 text-white hover:opacity-75"
      >
        <svg
          class="hi-solid hi-cube-transparent inline-block w-5 h-5 text-red-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Warehouse</span>
      </router-link>
      <!-- END Brand -->

      <!-- Close Sidebar on Mobile -->
      <div class="lg:hidden">
        <button
          @click="emits('mobileSidebarClosed')"
          type="button"
          class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-5 text-sm rounded border-transparent text-white opacity-75 hover:opacity-100 focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:opacity-75"
        >
          <svg
            class="hi-solid hi-x inline-block w-4 h-4 -mx-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <!-- END Close Sidebar on Mobile -->
    </div>
    <!-- END Sidebar Header -->

    <!-- Sidebar Navigation -->
    <div class="overflow-y-auto">
      <div class="p-4 w-full">
        <nav class="space-y-1">
          <router-link
            to="/"
            class="flex items-center space-x-3 px-3 font-medium rounded text-gray-200"
          >
            <span class="flex-none flex items-center opacity-50">
              <svg
                class="hi-outline hi-home inline-block w-5 h-5"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
            </span>
            <span class="py-2 flex-grow">Dashboard</span>
            <!-- span class="px-2 py-1 rounded-full text-xs font-medium leading-4 bg-opacity-10 text-gray-100 bg-gray-400">3</span -->
          </router-link>

          <!-- We will only display the namespaces section to users with world scope on publications or licenses
               until we filter.
          -->
          <SidebarResourceSection
            sectionName="namespaces"
            resourceTypeForPermission="namespaces"
            addNewPath="/namespaces/new"
            managePath="/namespaces"
            customIngestionHooksPath="/namespaces/custom_ingestion_hooks"
          />
          <SidebarResourceSection
            sectionName="licenses"
            resourceTypeForPermission="licenses"
            managePath="/licenses"
            addBatchPath="/licenses/newBatchLicenses"
          />
          <SidebarResourceSection
            sectionName="publications"
            resourceTypeForPermission="publications"
            managePath="/publications"
            addNewPath="/publications/new"
            addBatchPath="/publications/batch"
            addMarc21Path="/publications/marc21"
          />

          <SidebarResourceSection
            sectionName="periodicals"
            resourceTypeForPermission="periodicals"
            managePath="/periodicals"
            addNewPeriodicalPath="/periodicals/new"
            addManageSubscriptionsPath="/periodicalSubscriptions"
            addPeriodicalSubscriptionsBulkPath="/periodicalSubscriptions/bulk"
          />

          <SidebarResourceSection
            sectionName="audio jobs"
            resourceTypeForPermission="audiobooks"
            managePath="/audio/jobs"
          />

          <SidebarResourceSection
            sectionName="users"
            resourceTypeForPermission="users"
            addNewPath="/users/new"
            managePath="/users"
          />

          <div
            class="px-3 pt-5 pb-2 text-xs font-medium uppercase tracking-wider text-gray-400"
          >
            System
          </div>
          <router-link
            to="/usertokens"
            class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
          >
            <span
              class="flex-none flex items-center text-gray-400 group-hover:text-blue-500"
            >
              <svg
                class="hi-outline hi-briefcase inline-block w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                />
              </svg>
            </span>
            <span class="py-2 flex-grow">User Tokens</span>
          </router-link>

          <router-link
            v-if="canReadResourceTypeOnAllNamespaces('namespaces')"
            to="/admin"
            class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
          >
            <span
              class="flex-none flex items-center text-gray-400 group-hover:text-blue-500"
            >
              <svg
                class="hi-micro hi-wrench inline-block w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="none"
                stroke="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.5 8a3.5 3.5 0 0 0 3.362-4.476c-.094-.325-.497-.39-.736-.15L12.099 5.4a.48.48 0 0 1-.653.033 8.554 8.554 0 0 1-.879-.879.48.48 0 0 1 .033-.653l2.027-2.028c.24-.239.175-.642-.15-.736a3.502 3.502 0 0 0-4.476 3.427c.018.99-.133 2.093-.914 2.7l-5.31 4.13a2.015 2.015 0 1 0 2.828 2.827l4.13-5.309c.607-.78 1.71-.932 2.7-.914L11.5 8ZM3 13.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <span class="py-2 flex-grow">Admin Tools</span>
          </router-link>

          <router-link
            to="/changelog"
            class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
          >
            <span class="flex-none flex items-center opacity-50">
              <svg
                class="hi-outline hi-cog inline-block w-5 h-5"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </span>
            <span class="py-2 flex-grow" @click="changesSeen()">Changelog</span>

            <span
              v-show="changesNotSeenCount > 0"
              class="font-semibold inline-flex px-1.5 py-0.5 leading-4 text-xs rounded-full border border-blue-200 text-blue-700 bg-blue-100 dark:text-blue-50 dark:bg-blue-700 dark:border-blue-700"
              >{{ changesNotSeenCount }}</span
            >
          </router-link>
          <a
            href="javascript:void(0)"
            class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
          >
            <span class="flex-none flex items-center opacity-50">
              <svg
                class="hi-outline hi-lock-open inline-block w-5 h-5 text-red-300"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                />
              </svg>
            </span>
            <span class="py-2 flex-grow" @click="logOutUser()">Log out</span>
          </a>

          <div
            class="px-3 pt-5 pb-2 text-xs font-medium uppercase tracking-wider text-gray-400"
          >
            Documentation
          </div>
          <a
            href="https://warehouse-docs.pages.dev/docs/intro"
            class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
            target="_blank"
          >
            <span class="flex-none flex items-center opacity-50">
              <information-circle-icon
                class="hi-outline hi-cog inline-block w-5 h-5"
              />
            </span>
            <span class="py-2 flex-grow">Developer Documentation</span>
          </a>
        </nav>
      </div>
    </div>
  </nav>

  <router-link class="hidden" to="/">Home</router-link>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useAuth } from '../auth';
import SidebarResourceSection from './SidebarResourceSection.vue';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import { CHANGELOG_DATA } from '../Changes';

const props = defineProps({
  mobileSidebarOpen: { type: Boolean, required: true },
  desktopSidebarOpen: { type: Boolean, required: true },
});

const emits = defineEmits<{
  (e: 'mobileSidebarClosed'): void;
}>();

let {
  logout,
  user,
  canReadResourceTypeOnAllNamespaces,
  canWriteResourceTypeOnAllNamespaces,
} = useAuth();

const logOutUser = () => {
  logout({ returnTo: window.location.origin });
};

const lastSeenChangelogDate = ref('');
const changesNotSeenCount = ref(0);
const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

const stringToDate = (version: string) => {
  const split = version.split('-').map(Number);
  const date = new Date(split[0], split[1] - 1, split[2]).toISOString();
  return date;
};

const versions: string[] = CHANGELOG_DATA.map((entry) =>
  stringToDate(entry.version)
);

const getLastSeenChangelogDate = () => {
  const storedDate = localStorage.getItem('lastSeenChangelogDate');
  const lastSeenDate =
    storedDate && storedDate < oneMonthAgo.toISOString()
      ? oneMonthAgo.toISOString()
      : storedDate || oneMonthAgo.toISOString();

  localStorage.setItem('lastSeenChangelogDate', lastSeenDate);
  return lastSeenDate;
};

const getChangesSinceLastLogin = (versions: string[], lastSeenDate: string) => {
  if (!lastSeenDate) {
    return 0;
  }
  let notSeen = 0;
  for (const version of versions) {
    if (version < lastSeenDate) {
      break;
    }
    notSeen++;
  }
  return notSeen;
};

lastSeenChangelogDate.value = getLastSeenChangelogDate();
changesNotSeenCount.value = getChangesSinceLastLogin(
  versions,
  lastSeenChangelogDate.value
);

const changesSeen = () => {
  changesNotSeenCount.value = 0;
  const todayDate = new Date().toISOString();
  localStorage.setItem('lastSeenChangelogDate', todayDate);
};
</script>

<style scoped>
.router-link-active:not(#logo) {
  @apply text-gray-100;
  @apply bg-gray-800;
  @apply bg-opacity-50;
}
</style>
